import React from "react";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

const SpeakingPage = () => {
  const data = useStaticQuery(graphql`
    query {
      talks: allTalksYaml {
        edges {
          node {
            title
            description
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Talks |" />
      <h1 className="speaking-header">Some of my recent talks</h1>
      <section>
        {data.talks.edges.map(({ node }, id) => (
          <div key={node.title}>
            <section
              style={{
                marginBottom: `2em`,
              }}
            >
              <h1 className="talk-title">
                {/* <Link
                  to={`/project/${node.slug}`}
                  style={{ textDecoration: "none" }}
                  state={{ title: node.title }}
                > */}
                {node.title}
                {/* </Link> */}
              </h1>
              <p
                style={{
                  marginTop: `.2em`,
                  fontStyle: "italic",
                }}
              >
                {node.description}
              </p>
            </section>
          </div>
        ))}
      </section>
    </Layout>
  );
};

export default SpeakingPage;
